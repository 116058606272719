import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const OurPartnershipPage = () => {
  return (
    <Layout>
      <SEO title="Our Partnership" />

      <div className="flex flex-wrap mt-2 bg-white border border-gray-300">
        <div className="w-full p-4 md:p-10">
          <h1 className={`pb-4 border-b border-gray-300 mb-8`}>
            Our Partnership
          </h1>

          <p>
            Mitchells & Butlers operate some of the most successful pub and
            restaurant brands in the country and we want to share with you our
            knowledge and 'know how' to give you the best opportunity of
            operating a successful and profitable business. Whilst we do not
            offer our managed brands for lease we do recognise that our Lessees
            are individuals and need different levels of support depending on
            their background and experience. Consequently, we tailor our support
            to your specific requirements.
          </p>
          <p>
            The support package we offer for Mitchells & Butlers Lease Partners
            covers all possible elements needed to operate a successful pub. We
            start by getting the four basics right:
          </p>

          <h2>Pubs</h2>
          <p>
            It starts with the right pub and location and we have some of the
            best located sites in the industry.
          </p>

          <h2>Products</h2>
          <p>
            We are free to choose from an extensive list of drinks products with
            access to all the major brewers, with no minimum order quantity, to
            provide you with the flexibility to build your business your way.
          </p>

          <h2>Price</h2>
          <p>
            The price of our drinks products is very competitive within the tied
            lease industry, and starts from the first barrel with no barrelage
            targets!
          </p>

          <h2>Partnership</h2>
          <p>
            Our experienced Lease Operations Managers will work to forge a long
            term mutually beneficial partnership with you.
          </p>

          <p>In addition, we can also provide or source the following:</p>

          <ul>
            <li>On and off job training</li>
            <li>Regular quarterly drink and food stock-takes</li>
            <li>
              Regular Food Safety and Health and Safety consultancy audits to
              ensure you are working to the highest standards
            </li>
            <li>
              A planned maintenance programme to ensure that you comply with all
              your statutory requirements and certification
            </li>
            <li>
              An annual building inspection from one of our building surveyors
              to provide you with guidance for any on-going repair and
              maintenance issues
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  )
}

export default OurPartnershipPage
